export const SESSION_MAP_FRAGMENT = `
  fragment sessionMapFragment on Session {
    uid
    schemaCode
    name
    startTime
    startTimestamp
    endTime
    endTimestamp
  }
`;
